export default function Preguntas() {
    return (
    <section>
        <div className="container align-items-center ">
              <h2 className="fs-1 mb-2 text-center ">Preguntas frecuentes</h2>
              <p className="mb-4 text-center ">
              Accede a nuestro catálogo de expertos para iniciar tu camino de mejora personal
              </p>
            <div className="d-flex justify-content-center align-items-center">
            <div className="accordion col-md-10   text-left" id="accordionExample">


                <div className="accordion-item shadow m-4 text-left bg-dark ">
                <h2 className="accordion-header rounded bg-dark " id="headingOne">
                    <button className="accordion-button rounded bg-dark  text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">¿Qué es FullContent y cómo funciona?</button>
                </h2>
                <div className="accordion-collapse collapse show text-left" aria-labelledby="headingOne" data-bs-parent="#accordionExample" id="collapseOne">
                    <div className="accordion-body text-white text-left">
                    FullContent es una plataforma donde los creadores de contenido pueden vender sus fotos a sus seguidores. Los usuarios pueden explorar las galerías de fotos, comprar imágenes individuales o suscribirse a paquetes de fotos ofrecidos por los creadores.
                    </div>
                </div>
                </div>

                <div className="accordion-item shadow m-4 text-left text-white">
                <h2 className="accordion-header rounded bg-dark " id="headingTwo">
                    <button className="accordion-button collapsed rounded bg-dark  text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">¿Cómo puedo crear una cuenta en FullContent?</button>
                </h2>
                <div className="accordion-collapse collapse text-left" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" id="collapseTwo">
                    <div className="accordion-body text-white text-left">
                    Para crear una cuenta en FullContent, visita el sitio web oficial y haz clic en "Registrarse". Completa el formulario de registro con tu correo electrónico, contraseña y otros datos requeridos. También puedes registrarte usando tus cuentas de redes sociales.
                    </div>
                </div>
                </div>

                <div className="accordion-item shadow m-4 text-left bg-dark  text-white">
                <h2 className="accordion-header rounded bg-dark " id="headingThree">
                    <button className="accordion-button collapsed bg-dark  text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">¿Cuánto cuesta comprar fotos en FullContent?</button>
                </h2>
                <div className="accordion-collapse collapse text-left" aria-labelledby="headingThree" data-bs-parent="#accordionExample" id="collapseThree">
                    <div className="accordion-body  text-left">
                    El costo de las fotos en FullContent varía dependiendo del creador y del tipo de contenido. Los creadores establecen sus propios precios, que pueden variar desde unos pocos dólares por foto hasta precios más elevados para galerías exclusivas o fotos personalizadas.
                    </div>
                </div>
                </div>

                <div className="accordion-item shadow m-4 text-left">
                <h2 className="accordion-header rounded bg-dark " id="headingFour">
                    <button className="accordion-button collapsed bg-dark  text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">¿Cómo puedo ganar dinero en FullContent?</button>
                </h2>
                <div className="accordion-collapse collapse text-left" aria-labelledby="headingFour" data-bs-parent="#accordionExample" id="collapseFour">
                    <div className="accordion-body  text-left">
                    Puedes ganar dinero en FullContent vendiendo tus fotos. Sube tus fotos a la plataforma, establece tus precios y promueve tu contenido a tus seguidores. También puedes ofrecer paquetes de fotos y suscripciones para generar ingresos recurrentes.
                    </div>
                </div>
                </div>

                <div className="accordion-item shadow m-4 text-left">
                <h2 className="accordion-header rounded bg-dark " id="headingFive">
                    <button className="accordion-button collapsed bg-dark  text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">¿Es seguro usar FullContent?</button>
                </h2>
                <div className="accordion-collapse collapse text-left" aria-labelledby="headingFive" data-bs-parent="#accordionExample" id="collapseFive">
                    <div className="accordion-body  text-left">
                    FullContent implementa varias medidas de seguridad para proteger tanto a los creadores como a los compradores. Esto incluye encriptación de datos, políticas de privacidad estrictas y opciones de pago seguras. Sin embargo, como con cualquier plataforma en línea, es importante seguir las mejores prácticas de seguridad personal.
                    </div>
                </div>
                </div>

                <div className="accordion-item shadow m-4 text-left">
                <h2 className="accordion-header rounded bg-dark " id="headingSix">
                    <button className="accordion-button collapsed bg-dark  text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">¿Cómo puedo retirar mis ganancias de FullContent?</button>
                </h2>
                <div className="accordion-collapse collapse text-left" aria-labelledby="headingSix" data-bs-parent="#accordionExample" id="collapseSix">
                    <div className="accordion-body text-left">
                    Para retirar tus ganancias de FullContent, ve a la sección de "Mi Cuenta" y selecciona "Retirar Fondos". Podrás elegir entre varias opciones de pago, como transferencia bancaria, PayPal u otros métodos disponibles en tu país. Asegúrate de cumplir con los requisitos mínimos de retiro establecidos por la plataforma.
                    </div>
                </div>
                </div>
        


          </div>
        </div>

        <div className=" text-center">
        <a href="mailto:info@anvil.capital">¿Necesitas ayuda? <span className=" cursor text-decoration-underline">Contáctanos aquí </span></a>
        </div>

        </div>
        </section>)
} 