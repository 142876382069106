

export default function Post(item)  {
    return(
      <div className="card col-12 col-md-6   shadow m-2 bg-dark " >
          <div className="d-flex  align-items-center m-2 ">
              <div className="avatar avatar mt-2 mb-3 ">
                  <img className="avatar-img rounded-circle border border-white border-3 bg-dark" src={item?.profile_img ||  "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"} alt="" />
              </div>         
              <h5 className="text-white mx-1">@{item?.name}</h5>
          </div>
  
          <div className=" m-2 mt-0 ">
          {item?.caption}
  
          {/* {item?.video === true   ? 
              <>
              <ReactPlayer className="w-90 min-vh-50 mt-0"    style={{borderRadius:'16px',overflow:'hidden'}} controls={true} url={this.state.postData?.url} />
              </>
          :<> */}
           <div  className=" mt-2 d-flex align-items-center justify-content-center">
            <img src={item?.url} className="img-fluid m4  mx-auto  mh-50  border-rounded"></img>

            </div>
          {/* </> */}
          {/* } */}
          </div>
          <div className="hstack gap-5 d-flex align-items-center justify-content-center">
              <div className=" d-flex align-items-center justify-content-center p-3 text-center">
                  <a className="text-center"><i className="fa fa-likes h-"></i> Comprado 10 </a>
              </div>
              <div class="vr m-2"></div>
              <div className=" d-flex align-items-center justify-content-center p-3 text-center">
                  <a className="text-center"><i className="fa fa-likes h-"></i> Comprado 10 </a>
              </div>
          </div>
  

          </div>
    )
  
  }