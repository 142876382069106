import React, { useEffect , useState} from "react";


import { useAuthState } from "react-firebase-hooks/auth";


import { logout ,auth} from "../firebase";

import Logo from "../assets/favicon.png"
import { Link } from "react-router-dom/cjs/react-router-dom";


function NavbarComponent() {
  const [user, loading] = useAuthState(auth);
  const [LogButton, setLogButton] = useState(false);

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (user)
     { setLogButton(false) } else {
       setLogButton(true)
     }
  }, [user, loading]);

    function logoutSesion() {
      logout()
      setLogButton(true)
    }


    return (
          <>
          <header className="navbar-dark  ">
          {/* Nav START */}
          <nav className="navbar navbar-expand-xl navbar-dark ">
            <div className="container-fluid px-3 px-xl-5 width-100  d-flex">
              {/* Logo START */}
              <a href="/" className="navbar-brand font d-flex align-items-center text-light" >
                 <img src={Logo} className="icon mx-1" width={"40px"}></img>  Fullcontent.io
              </a>
              {/* Logo END */}
              {/* Responsive navbar toggler */}
              <button
                className="navbar-toggler ms-auto bg-transparent"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-animation bg-transparent">
                  <span />
                  <span />
                  <span />
                </span>
              </button>
              {/* Main navbar START */}
              <div className="navbar-collapse w-100 collapse justify-content-end align-items-center" id="navbarCollapse">
                {/* Nav category menu START */}
              
                {/* Nav category menu END */}
                {/* Nav Main menu START */}
                <ul className="navbar-nav navbar-nav-scroll text-center  ">
                  {/* Nav item 1 Demos */}
                 
                  {/* Nav item 3 Account */}

                  <li className="nav-item">
                    <a href="https://theraphya.com/nosotros" className="nav-link"  
                    >Agencia Modelos
                  </a>
                  </li>
                
                    
                 <li className="nav-item">
                    <a href="https://theraphya.com/nosotros" className="nav-link"  
                    >Nosotros
                  </a>
                  </li>
                
                



                <li className="nav-item d-flex align-items-center justify-content-center">
                    {" "}
                    <a
                      className="btn text-primary   px-2  text-instagram d-flex text-center"
                      href="https://instagram.com/theraphya.app"
                      target="__blank"
                    >
                      <i className="fab fa-fw fa-instagram fs-4" /> <span className="d-lg-none d-md-none d-block">Instagram</span>
                    </a>{" "}
                  </li>

                  <li className="nav-item d-flex align-items-center justify-content-center">
                    {" "}
                    <a
                      className="btn text-primary   px-2  text-instagram d-flex text-center"
                      href="https://tiktok.com/@theraphya.app"
                      target="__blank"
                    >
                      <i className="fab fa-fw fa-tiktok fs-4" /> <span className="d-lg-none d-md-none d-block">Tiktok</span>
                    </a>{" "}
                  </li>
                </ul>
                {/* Nav Main menu END */}
                {/* Nav Search START */}
                <div className=" nav-item  ms-3 d-flex justify-content-center">
         
                      <div  className="dropdown nav-link">
                  {LogButton ?
                  <div className="mt-3 mb-3">        
                    <Link to="/register" className="btn btn-primary  ">
                  <span>  <i className="fas fa-user text-white"></i> Crea tu cuenta</span>
                    </Link>
                  </div>
                  :
                  <>        

                  
                    <Link to="/profile" className="btn btn-primary">
                      <span >  <i className="fas fa-user text-white"></i> Tu cuenta</span>
                    </Link>
                  </>
     
                    }            
                </div>

                </div>
                    {/* Nav Search END */}
                  </div>
                  {/* Main navbar END */}
                  {/* Profile START */}
            
                  {/* Profile START */}
                </div>
          </nav>

          {auth.currentUser ?
          <div className="menuBottom onlyMob row justify-content-center align-items-center shadow bg-dark">
              <div className="col col-4  d-flex justify-content-center align-items-center">
                  <Link to="/profile" className="" >
                      <i className="bi bi-person-circle fs-3"> </i>
                    </Link>
              </div>

              <div className="col col-4  d-flex justify-content-center align-items-center">
                  <Link to="/add" className="" >
                  <i className="bi bi-plus-circle fs-3"> </i>
                    </Link>
              </div>

              <div className="col col-4  d-flex justify-content-center align-items-center">
                  <Link to="/wallet" className="" >
                      <i className="bi bi-wallet fs-3"> </i>
                    </Link>
              </div>
          </div>
          : null }
          {/* Nav END */}
        </header>
                {/* */}
             
              
          </>
    
    )
  

}

export default NavbarComponent;
