import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


import './Theme.css';

import Login from "./Auth/Login";
import Register from "./Auth/Register";
import Reset from "./Auth/Reset";
import Landing from "./Main/Landing";
import Profile from "./Main/Profile";
import Terms from "./Main/Terms";
import Edit from "./Main/Edit";
import PostView from "./Main/PostView";





import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTop"
import NavbarComponent from "./Components/Navbar";



import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import { Toaster } from 'react-hot-toast';


import { auth , db} from "./firebase"
import { useEffect } from "react";
import Wallet from "./Main/Wallet";
import AddContent from "./Components/AddContent";




const stripePromise = loadStripe('pk_live_51OWGVKGs4sg3xpWot0AU4IBpMtQj5a3At14WLs9kiVOEqMweQwQHMBFQYGlt5vcPYzJQ85nhpvUgvumpgbVDjDdi00FEguj4UD');

function App() {

  useEffect(() => {
    setTimeout(() => {
      if(auth.currentUser === true ) {db.collection("users").doc(auth.currentUser.uid).update({lastStatus: Date.now()})} }, 3000);
  }, [])
  
  return (

    <div className="app">
      <Toaster></Toaster>
      <Elements stripe={stripePromise}>
      <Router>
      <ScrollToTop/>

      <NavbarComponent/>
        <Switch>
          <Route exact path="/" component={Landing} />   
          <Route exact path="/profile" component={Profile} />        
          <Route exact path="/register" component={Register}  />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/reset" component={Reset} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/wallet" component={Wallet} />
          <Route exact path="/edit" component={Edit} />
          <Route exact path="/post" component={PostView} />

          <Route exact path="/add" component={AddContent} />



        </Switch>
        <Footer/>
      </Router>
      </Elements>
    </div>
  );
}

export default App;
