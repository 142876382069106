import React from "react";

import {  auth, db, logout } from "../firebase";


import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Button, Modal } from "react-bootstrap";

import toast from 'react-hot-toast';
import AddContent from "../Components/AddContent";

const BASE_TRANSAC = 'https://europe-west1-fullhub.cloudfunctions.net/app/transactions/';

  function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }


  function getQueryVariable(variable)
{
        var query = window.location.search.substring(1);
        console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    // alert(pair[1])//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
        if(pair[0] == variable){return pair[1];}
         }
         return(false);
}
class Wallet extends React.Component {



  constructor(props) {
    super(props) 
    this.state = {
        transactions:[],
        taken: false,
        name:"",
        username:"",
        description:"",
        birthday: "",
        price:0,
        retireMethod: "paypal",
        balance:0,
        retireAmount:0,
        profileLink: "",
        retireAccount: null,
    }

  } 
  
  componentDidMount() {
   
    this.getUser()

    setTimeout(() => {
      this.getUser()
    }, 1200);

    if(getQueryVariable('tab')) {
      this.setState({tab: getQueryVariable('tab')})
    }
}


getTransactions = () => {
  fetch(`${BASE_TRANSAC}${auth.currentUser.uid}`)
    .then((res) => res.json())
    .then((resJson) => { 
      var arr = resJson;
      arr.sort(function(a, b) {return b.date - a.date });
      console.log(arr)

  
      
      this.setState({transactions:arr})

      arr.filter(obj => {
        return obj.donator !== auth.currentUser.uid;
      });

      var balance =arr.map(item => Number(item.amount) ).reduce((prev, next) => prev + next);
      this.setState({balance:balance})
    })
    .catch(console.error)
}


  async getUser() {

    if(auth.currentUser) {
        await db.collection('users').doc(auth.currentUser.uid).onSnapshot((doc) => {
        const userData = doc.data();
        this.setState(userData);
        // this.getTransactions();
        if (doc && doc.exists) {
          this.setState({oldusername: userData.username, priceOriginal: userData.price});
  
      } else {
        this.setState({new: true})
      }
      
    });
    } 
};


  updateWithUsername = async () => {
    auth.currentUser.updateProfile({photoURL: this.state.img, displayName: this.state.username});
    await db.collection('users').doc(auth.currentUser.uid).set({...this.state, taken:false,email: auth.currentUser.email, uid: auth.currentUser.uid,lastupdate: Date.now(), balance: 0, transactions: null},{merge:true});
    
    if( this.state.username !== auth.currentUser.displayName) {
    //   fetch(`${BASE_URL + auth.currentUser.uid  + "/" + this.state.username}`);
    }
    setTimeout(() => {
      toast.success('Your changes had been saved correctly')
    }, 500);
  }

  updateUser = async () => {
   

    if(this.state.profileLink !== "" &&  validURL(this.state.profileLink) == true) {
      this.setState({profileLinkError: false})
      } else {
        this.setState({profileLinkError: true, profileLink: ""})
    }

    const username = await db.collection('usernames').doc(this.state.username).get();
    if (username.exists ) { 
        if(this.state.username === this.state.oldusername) {
          this.updateWithUsername()
        } else {
          const doc = username.data()
          if(auth.currentUser.uid  === doc.uid) {
            this.updateWithUsername()
          } else {
              this.setState({taken:true})
          }
        }
      } else {
        this.updateWithUsername()
      }

  
    
   
  }


  renderTransactions() {
    if(this.state.transactions?.length > 0 ) {
      return (
        <>
          {this.state.transactions?.map(data => (
              <div className="hstack gap-2 mb-3" key={data.date}>
              {/* Avatar */}
              <div className="avatar avatar-story d-flex justify-content-center align-items-center">
              <h3>
              {data.retire === true ? 
               <i className="fa-solid fa-arrow-down mt-2 primary" /> 
              : 
              <i className="fa-solid fa-arrow-up mt-2 primary" /> 
              }  
              </h3>   
              </div>
              {/* Title */}
              <div className="overflow-hidden">
                <a className="h6 mb-0"> {data.amount?.toLocaleString()}€ </a>
                <p className="mb-0 small text-truncate">{data.type}</p>
                <span> {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(data.date)}</span>
              </div>
              {/* Button */}
              <a className="btn btn-primary-soft rounded-circle icon-md ms-auto" ><i className="fa-solid fa-euro"> </i></a>
            </div>
        ))}
        </>
      )
    } else {
      return(
        <>
          <h6 className="text-center">No has hecho ninguna transacción</h6>
        </>
      )
    }
    
  }


    
   borrarMetodo = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to make any payments until you add a new card",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        db.collection("users").doc(auth.currentUser.uid).update({paymentMethodId: null, paymentMethod:null})
        this.getUser();
     
        toast.success('Your card was remove from the account')
      }
    })

  }


  updatePrice = async () => {

    console.log(this.state.price)
    // Change this is wrong doing  


    if(this.state.priceOriginal === this.state.price) {
      return null;
    } else {

      Swal.fire({
        title: 'Are you sure?',
        text: "You would change the current price in your account",
        showCancelButton: true,
        confirmButtonColor: '#9C7DF2',
        cancelButtonColor: '#adb5bd',
        confirmButtonText: 'Change price',
        background:"#000",
        color: "#fff"
      }).then((result) => {
        if (result.isConfirmed) {
        this.priceUpdateDB()

        }
      })


        
    }

  }

  priceUpdateDB = async () => {
   
    await db.collection("users").doc(auth.currentUser.uid).update({price: Number(this.state.price)}).then(() => {
      toast.success('Precio de perfil actualizado')
    });
  }


  renderInputMethod() {
  if(this.state.retireMethod === "paypal") {
          return (
            <input 
              type="Paypal account email" 
              value={this.state.retireAccount}
              onChange={(e) => this.setState({retireAccount:e.target.value})}
              className="form-control form-control-lg mb-2" 
              name="amount" 
              id="amount" 
              placeholder="janedoe@mail.com" 
              required 
          />
          )
        } else {
          return (
            <input 
            type="Bank account IBAN" 
            value={this.state.retireAccount}
            onChange={(e) => this.setState({retireAccount:e.target.value})}
            className="form-control form-control-lg mb-2" 
            name="amount" 
            id="amount" 
            placeholder="AT 48 3200 0000 1234 5864" 
            required 
            />
          )
        }

  }

  retire() {
      if(this.state.retireAmount === 0 || this.state.retireAccount === null) {
        
        if(this.state.retireAccount === null ) {
          toast.error("Rellena todos los campos para completar la retirada de dinero")

        } else {
        toast.error("You cannot withdrawal more than the balance that you have in you account")
        }
      
      } else {
        this.setState({retireLoading: true})
     
      if(this.state.retireAmount < 1) {
      
        toast.error("You cannot withdrawal, you cannot withdrawal less than 1€")
      } else {
        if(this.state.retireAmount > this.state.balance) {
         
            toast.error("Withdrawal failed", "You cannot withdrawal more than the balance that you have in you account")
        } else {
          const data = {
              amount: -this.state.retireAmount,
              date: Date.now(),
              user: auth.currentUser.uid,
              retire: true,
              method: this.state.retireMethod,
              retireAccount: this.state.retireAccount,
              type: `Withdrawal of money to ${this.state.retireMethod} account`,
              }
              
          db.collection("users").doc(auth.currentUser.uid).collection("transactions").add(data)
  
          db.collection("withdraws").add(data);
  
          setTimeout(() => {
            this.getUser()
            this.setState({showModal: false, retireLoading: false})
            toast.success('Retiro de dinero efectuado correctamente')
          }, 2000);
          
        }  
      }
  }
  }

  render() {

      return (
      <main className="mt-4 mb-5">
        {/* Modal retire more  */}
        <Modal variant="dark" show={this.state.showModal} onHide={() => this.setState({showModal: false})}    aria-labelledby="bg-dark contained-modal-title-vcenter border-none border-rounded"   centered>
          <Modal.Header  className="bg-dark text-white border-none border-0" >
            <Modal.Title>Retira fondos cuenta</Modal.Title>
          </Modal.Header>
          
          <Modal.Body className="  text-left bg-dark">
            <label className="mb-2">Cantidad a retirar</label>
            <input 
                type="email" 
                value={this.state.retireAmount}
                onChange={(e) => this.setState({retireAmount:e.target.value})}
                className="form-control form-control-lg mb-2" 
                name="amount" 
                id="amount" 
                placeholder="100€" 
                required 
            />
            <div className="d-flex">
                <a className="badgeDonation"  onClick={() => this.setState({retireAmount:this.state.balance})}>Retirar todo</a>
                <a className="badgeDonation" onClick={() => this.setState({retireAmount:this.state.balance * 0.5})}>50%</a>
            </div>
            <label>Seleccioa tipo de retirada</label>
            <select className="form-control mt-2 mb-2"   value={this.state.retireMethod}  onChange={(e) =>  this.setState({ retireMethod : e.target.value})} id="exampleFormControlSelect1">
                <option value={"paypal"}>Paypal transferencia - 1-2 dias </option>
                <option value={"bank"}>Transferencia bancaria  -  3-5 dias</option>

            </select>
            {this.renderInputMethod()}


            <p className="h6 mt-2">Vas a retirar {this.state.retireAmount}€</p>           
            <br/>
           
           { this.state.retireLoading ?
            <div className="text-center mb-4">
            <div className="spinner-border text-primary" role="status">
                <h1 className="sr-only">Loading...</h1>
            </div>
            </div>
            : null 
           }
            <div className="container d-flex justify-content-center align-items-center ">
            <small className="underline text-center cursor" target="__blank" href="https://fullcontent.me/terms">Acorde con los términos y condiciones de Fullcontent.me</small>
            </div>
            

            <br/>

     
            </Modal.Body>

            
          <Modal.Footer className="bg-dark border-none">

          <hr className="bg-dark"/>
            <Button variant="outline-danger" className="text-danger btn-outline-danger" onClick={() => this.setState({showModal: false})}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={() => this.retire()}>
              Retirar 
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Container START */}
        <div className="container">
          <div className="row">

              {/* Main content START */}
              <div className="col-lg-6   vstack gap-4">
              {/* Setting Tab content START */}
              <div className="tab-content py-0 mb-0  p-0  m-0 mt-0 ">
                {/* Account setting tab START */}                   

                <div >
                  {/* Card START */}
                  
                  <div className="card bg-dark">
                    {/* Card header START */}
                    <div className="card-header border-0 pb-0 bg-dark">
                      <div className="row">
                      <div className="col-12 col-md-6">
                        <h5 className="card-title">Transacciones</h5>
                        <p className="mb-0">Todas las ventas y transferencias hechas en tu cueta </p>
                        <div className="col-6 col-md-6 mt-3">
                        <a   onClick={() => this.setState({showModal: true})} className="btn btn-sm btn-primary mb-3 float-right">Retirar dinero</a>
                      </div>
                    
                      </div>
                      <div className="col-12 col-md-6 ">
                        <label>Balance cuenta</label>
                        <h2>{this.state.balance}€</h2>
                      </div>
                      </div>
                    
                   
                    </div>
                    </div>

                    <div className="roumded mt-3">
                    {/* Card header START */}
                    <div className="card-body bg-dark border-rounded">
                          {this.renderTransactions()}
                    </div>
                    
                    {/* Card body END */}
                    </div>
                  {/* Card END */}
                </div>
           
            
              </div>
              {/* Setting Tab content END */}
            </div>
            {/* Sidenav START */}
            <div className="col-lg-3 col-md-3  p-0 mb-0 pb-0">
              {/* Advanced filter responsive toggler START */}
              {/* Divider */}
             
              {/* Advanced filter responsive toggler END */}
          
            </div>
            {/* Sidenav END */}
          
          </div> {/* Row END */}
        </div>
        {/* Container END */}
      </main>

      
      );

    }
  }


export default Wallet;
