import React, { useCallback, useState } from 'react'
import Dropzone, { useDropzone } from 'react-dropzone'

import styled, { css } from "styled-components";

import { uploadFromBlobAsync } from './storage'

const StyledLabel = styled.label`
  display: flex;
  height: 200px;
  margin: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ dragEnter }) =>
    dragEnter &&
    css`
      border-color: #666;
    `}
`;


function DragDrop({onDone,onlyButton,classButton}) {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [video, setVideo] = useState(null)
  const [message, setMessage] = useState(null)
  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles?.[0]

    if (!file) {
      return
    }

    setIsLoading(true)
    console.log(file.type)
   
    setError(null)
    setMessage(null)


    if(file.type == "video/quicktime" || file.type == "video/mp4") {
     
    try {
     
      const url = await uploadFromBlobAsync({
        blobUrl: URL.createObjectURL(file),
        name: `${file.name}_${Date.now()}`,
      })
        onDone({url:url,video:true})

        } catch (e) {
      setIsLoading(false)
      setError(e.message)
      return
    }


    } else {

      try {
     
        const url = await uploadFromBlobAsync({
          blobUrl: URL.createObjectURL(file),
          name: `${file.name}_${Date.now()}`,
        })
          onDone({url:url,video:false})
  
          } catch (e) {
        setIsLoading(false)
        setError(e.message)
        return
      }
  
  
    }
    setIsLoading(false)
    setMessage('File was uploaded 👍')
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
    
        {onlyButton === true ? 
        <button className="btn   btn-primary text-white ms-2">
        {isLoading && onlyButton === true ? 
        <>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </>
        : 
        
        <i  className={classButton} />
        }
        Selecciona imagen
        <input
            className='input' type="file" accept="image/*" {...getInputProps()}
          />
        </button>
        : 
        <div  className="d-flex d-md-flex d-none   bropbox dropzone dropzone-default card shadow-none container p-3 " >
        <StyledLabel
            htmlFor="images-upload"
        >
      <input
          className='input' type="file" accept="image/*" {...getInputProps()}
        />
        Drag images or select from gallery
        </StyledLabel>
      
        
        
        </div>
        }

    
        
         {/* <Dropzone  onDrop={onDrop}></Dropzone> */}
        {isLoading && onlyButton !== true ? (
          <div className='d-flex justify-content-start align-items-center h-50px'>
           <div className="spinner-border mt-2 primary  small" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          <p className='text-center justify-content-center align-items-center p-2 mt-4 mx-2 '> Processing media </p>
          </div>
           
        ) : isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <div>   {onlyButton !== true ? <p className='mt-2 primary  underline'>Selecciona media </p> : null}</div>
        )}
    </div>

  )
}

export default DragDrop