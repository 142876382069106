// 1653318832790-ntqIrWzu4ybI7XZHbPRpG8oTTks1


import React from "react";

import {  auth, db } from "../firebase";
import Post from "../Components/Post";
import { Button } from "react-bootstrap";

class PostView extends React.Component {

  constructor(props) {
    
    super(props) 
    this.state = {
        id: "1653320107394-tn8BG3CsZvbhjxXHPxVy2MGQ67d2"
    }
  }



  componentDidMount() {
    this.getPost();
    this.getUser()
    setTimeout(() => {
        this.getUser()
        this.getPost();
    }, 2000);
  }

   async getPost() {
   await db.collection('posts').doc(this.state.id).get()
        .then(doc => {
            const data = doc.data();
            this.setState({postData:data})
        })
  }





  getUser = async () => { 
      if(auth.currentUser) {
        await db.collection('users').doc(auth.currentUser.email).get()
        .then(doc => {
            const data = doc.data();
            this.setState({userActive:data})
        
        })
          this.setState({loaded: true})

        } else {
        }
  }




  render() {
    return ( 
    <>
    <div className="d-flex align-item-center justify-content-center ">
    { Post({url:"https://p16-va.lemon8cdn.com/tos-maliva-v-ac5634-us/oQT1rfSNGQNtDuAIAmoEDzCJIBTFEsIelQEhAB~tplv-tej9nj120t-origin.webp"})}
    </div>
    </>
    )
  }

 }


  export default PostView;