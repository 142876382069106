import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import {
  auth,
  signInWithEmailAndPassword,
  signInWithGoogle,
} from "../firebase";


import { useAuthState } from "react-firebase-hooks/auth";

function Login2() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("");

  const [user, loading] = useAuthState(auth);
  const history = useHistory();


  function LoadUser() {

    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) { history.replace("/profile")  } 
  }

  useEffect(() => {
    LoadUser()
  }, [user, loading]);


  return(
    <div className="">
    <main>
      <div className="row g-0">
        {/* left */}
        <div className="col-sm-10 col-lg-5 d-flex m-auto vh-100">
          <div className="row w-100 m-auto">
            <div className="col-lg-10 my-2 m-auto">

            <div className="RegisterForm">
             
           
            <div className="RegisterForm ">
                <div className="container p-2">
                <h2 className="mb-0">Inicia sesión en tu cuenta de Theraphya
                </h2>
                <p className="mb-0">Entra en tu cuenta</p>

                {/* Social buttons */}
                          
                {/* Form START */}
                <form>
                  {/* Email */}
                  <div className="input-floating-label form-floating mb-4">
                    <input type="email" focus
                           className="form-control mt-5 focus"
                           id="floatingInput"
                           placeholder="name@example.com"
                           value={email}
                          onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="floatingInput">Dirección email</label>
                  </div>

                  {/* Password */}
                  <div className="input-floating-label form-floating position-relative">
                    <input 
                        className="form-control fakepassword pe-6" id="psw-input"
                        placeholder="Enter your password"
                        value={password}
                         type={
                            showPassword ? "text" : "password"
                         }
                        onChange={(e) => setPassword(e.target.value)}
                        />
                    <label htmlFor="floatingInput">Contraseña</label>
                    <span className="position-absolute top-50 end-0 translate-middle-y p-0 me-2">
                      <i className="fakepasswordicon fas fa-eye-slash cursor-pointer p-2" 
                      onClick={() =>
                          setShowPassword(!showPassword)
                      }
                      ></i>
                    </span>
                  </div>
                  {/* Pswmeter */}

                  <div className="mb-4 mt-3">
                    <div className="">
                      <label className="" htmlFor="checkbox-1" >Has olvidado la contraseña <Link to="/reset">Resetea tu contraseña</Link></label>
                    </div>
                  </div>
                  {/* Button */}
                  <div className="align-items-center mt-0">
                    <div className="d-grid">
                      <button className="btn btn-primary mb-0" type="button" onClick={() => signInWithEmailAndPassword(email.toLowerCase(),password)}>Inicia sesión</button>
                    </div>
                  </div>
                </form>

                  <div id="pswmeter" className="mt-3"></div>
                  
            
                  <div className="position-relative my-5">
                    <hr/>
                    <p className="small position-absolute top-50 start-50 translate-middle bg-body px-4 rounded text-dark">O</p>
                  </div>

                      <div className="row mt-2 mb-3">
                  {/* Social btn */}
                  <div className="col-xl-12 d-grid">
                    <a onClick={() => signInWithGoogle()} className="btn border-primary  mb-2 mb-xxl-0 text-primary"><i className="fab fa-fw fa-google text-primary text-google-icon me-2"></i>Inicia con Google</a>
                  </div>
                  
                  </div>
                  </div>
                </div>

                               
              </div>

    
              {/* Sign IN link */}
              <div className="mt-4 text-center ">
                <span>¿No tienes cuenta? <Link to="/register"> Regístrate </Link></span>
              </div>
              
            </div>
          </div>
        </div>

        </div>
  </main>

  </div>
  )
}

export default Login2;
