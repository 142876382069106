import React from "react";

import {  auth, db, logout } from "../firebase";
import SelectImage from "../Components/SelectImage";



import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Button, Modal } from "react-bootstrap";

import toast from 'react-hot-toast';
import AddContent from "../Components/AddContent";

const BASE_URL = 'https://europe-west1-fullhub.cloudfunctions.net/app/changeUsername/'
const BASE_TRANSAC = 'https://europe-west1-fullhub.cloudfunctions.net/app/transactions/';


const preciosLive = 
[
    {
        price:2.5,
        "m1":  "price_1LpF5YA4hUlYJxlOJy8hlvns",
        "m12": "price_1LpU7mA4hUlYJxlORqYHJB8n",  // Off 30%
    },
    {
        price:5,
        "m1":  "price_1LpF5YA4hUlYJxlOJy8hlvns",
        "m12": "price_1LpU8GA4hUlYJxlOPDCqNOO0",  // Off 30%
    },
    {
        price:7.5,
        "m1":  "price_1LpFBMA4hUlYJxlOHRFRYldX",
        "m12":"price_1LpFFrA4hUlYJxlOR8IuhWjC", // Off 30%
    },
    {
        price:10,
        "m1":  "price_1LpFGRA4hUlYJxlOsFhRZugp",
        "m12": "price_1LpFHMA4hUlYJxlOtHjMqVgG", // Off 30%
    },
    {
        price:12.5,
        "m1":  "price_1LpU4CA4hUlYJxlOMvDsz7Pn",
        "m12": "price_1LpU6dA4hUlYJxlOo2kwmwrq", // Off 30% 
    },
    {
        price:15,
        "m1":  "price_1LpUAKA4hUlYJxlOir4CEz9N",
        "m12": "price_1LpU9zA4hUlYJxlO7eNlOmqf", // Off 30% 126€
    },
    {
        price:17.5,
        "m1":  "price_1LpUAVA4hUlYJxlObDHPOAxO",
        "m12": "price_1LpUB0A4hUlYJxlObQvLzwf3", //  Off 30% 147
    },
    {
        price:20,
        "m1":  "price_1LpUBEA4hUlYJxlOq0v2QK7K",
        "m12": "price_1LpUCnA4hUlYJxlOAQek5aGw", // Off 30%  168
    },
    {
        price:22.5,
        "m1":  "price_1LpUDMA4hUlYJxlOeyIlcWYz",
        "m12": "price_1LpUDiA4hUlYJxlOcANkKwul",  // Off 30%  189€
    },
    {
        price:25,
        "m1":  "price_1LpUEtA4hUlYJxlOF8iymzxQ",
        "m12": "price_1LpUFDA4hUlYJxlO830BWN4R",  // Off 30%  210€
    },
    {
        price:30,
        "m1":  "price_1LpUGPA4hUlYJxlOODQrQupt",
        "m12": "price_1LpUGbA4hUlYJxlO0LLVHvuh",  // Off 30%  252€
    },
    {
      price:35,
      "m1":  "price_1LpUHKA4hUlYJxlO0Wm4O7QP",
      "m12": "price_1LpUHbA4hUlYJxlOt04xMByw",  // Off 30%  294€
    }
    ,
    {
        price:40,
        "m1":  "price_1LpUI0A4hUlYJxlOMYzrDxd9",
        "m12": "price_1LpUISA4hUlYJxlOxp27b93g",  // Off 30%  336€
    },
    {
      price:45,
      "m1":  "price_1KyRDcEIzmvgggMujAiXSteY",
      "m12": "price_1LpUJLA4hUlYJxlOFXxsmmiT",  // Off 30%  378€
    },
    {
      price:50,
      "m1":  "price_1LpUJeA4hUlYJxlOi4s8LqAZ",
      "m12": "price_1LpUKfA4hUlYJxlOB310EJbs",  // Off 30%  420€
    },


]


const preciosTest = 

[
    {
        price:2.5,
        "m1":  "price_1LpUTXA4hUlYJxlOlzB37a6u",
        "m12": "price_1LpUVRA4hUlYJxlObR2MPoM3",  // Off 30%
    },
    {
        price:5,
        "m1":  "price_1LpUV7A4hUlYJxlOrjwzcOTJ",
        "m12": "price_1LpUUpA4hUlYJxlOLrisqwlV",  // Off 30% 42€
    },
   
    {
        price:15,
        "m1":  "price_1LpUUAA4hUlYJxlOrGNFBPn0",
        "m12": "price_1LpUURA4hUlYJxlOVrhYpJvh", // Off 30% 126€
    },

    {
      price:50,
      "m1":  "price_1LpUU2A4hUlYJxlONvqbDZvw",
      "m12": "price_1LpUTtA4hUlYJxlOjNUMJSsi",  // Off 30%  420€
    },


]

const images =  {
    amx:"../assets/images/cards/amx.png",
    visa: "../assets/images/cards/visa.png",
    mastercard: "../assets/images/cards/mastercard.png",

  }


  function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }


  function getQueryVariable(variable)
{
        var query = window.location.search.substring(1);
        console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    // alert(pair[1])//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
        if(pair[0] == variable){return pair[1];}
         }
         return(false);
}
class Settings extends React.Component {



  constructor(props) {
    super(props) 
    this.state = {
        transactions:[],
        taken: false,
        name:"",
        username:"",
        description:"",
        birthday: "",
        price:0,
        retireMethod: "paypal",
        balance:0,
        retireAmount:0,
        profileLink: "",
        retireAccount: null,
    }

  } 
  
  componentDidMount() {
   
    this.getUser()

    setTimeout(() => {
      this.getUser()
    }, 1200);

    if(getQueryVariable('tab')) {
      this.setState({tab: getQueryVariable('tab')})
    }
}


getTransactions = () => {
  fetch(`${BASE_TRANSAC}${auth.currentUser.uid}`)
    .then((res) => res.json())
    .then((resJson) => { 
      var arr = resJson;
      arr.sort(function(a, b) {return b.date - a.date });
      console.log(arr)

  
      
      this.setState({transactions:arr})

      arr.filter(obj => {
        return obj.donator !== auth.currentUser.uid;
      });

      var balance =arr.map(item => Number(item.amount) ).reduce((prev, next) => prev + next);
      this.setState({balance:balance})
    })
    .catch(console.error)
}


  async getUser() {

    if(auth.currentUser) {
        await db.collection('users').doc(auth.currentUser.uid).onSnapshot((doc) => {
        const userData = doc.data();
        this.setState(userData);
        // this.getTransactions();
        if (doc && doc.exists) {
          this.setState({oldusername: userData.username, priceOriginal: userData.price});
  
      } else {
        this.setState({new: true})
      }
      
    });
    } 
};


  updateWithUsername = async () => {
    auth.currentUser.updateProfile({photoURL: this.state.img, displayName: this.state.username});
    await db.collection('users').doc(auth.currentUser.uid).set({...this.state, taken:false,email: auth.currentUser.email, uid: auth.currentUser.uid,lastupdate: Date.now(), balance: 0, transactions: null},{merge:true});
    
    if( this.state.username !== auth.currentUser.displayName) {
    //   fetch(`${BASE_URL + auth.currentUser.uid  + "/" + this.state.username}`);
    }
    setTimeout(() => {
      toast.success('Your changes had been saved correctly')
    }, 500);
  }

  updateUser = async () => {
   

    if(this.state.profileLink !== "" &&  validURL(this.state.profileLink) == true) {
      this.setState({profileLinkError: false})
      } else {
        this.setState({profileLinkError: true, profileLink: ""})
    }

    const username = await db.collection('usernames').doc(this.state.username).get();
    if (username.exists ) { 
        if(this.state.username === this.state.oldusername) {
          this.updateWithUsername()
        } else {
          const doc = username.data()
          if(auth.currentUser.uid  === doc.uid) {
            this.updateWithUsername()
          } else {
              this.setState({taken:true})
          }
        }
      } else {
        this.updateWithUsername()
      }

  
    
   
  }


  renderTransactions() {
    if(this.state.transactions  == [] ) {
      return (
        <>
          {this.state.transactions?.map(data => (
              <div className="hstack gap-2 mb-3" key={data.date}>
              {/* Avatar */}
              <div className="avatar avatar-story d-flex justify-content-center align-items-center">
              <h3>
              {data.retire === true ? 
               <i className="fa-solid fa-arrow-down mt-2 primary" /> 
              : 
              <i className="fa-solid fa-arrow-up mt-2 primary" /> 
              }  
              </h3>   
              </div>
              {/* Title */}
              <div className="overflow-hidden">
                <a className="h6 mb-0"> {data.amount?.toLocaleString()}€ </a>
                <p className="mb-0 small text-truncate">{data.type}</p>
                <span> {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(data.date)}</span>
              </div>
              {/* Button */}
              <a className="btn btn-primary-soft rounded-circle icon-md ms-auto" ><i className="fa-solid fa-euro"> </i></a>
            </div>
        ))}
        </>
      )
    } else {
      return(
        <>
          <h6 className="text-center">No tienes transacciones en tu cuenta </h6>
        </>
      )
    }
    
  }


    
   borrarMetodo = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to make any payments until you add a new card",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        db.collection("users").doc(auth.currentUser.uid).update({paymentMethodId: null, paymentMethod:null})
        this.getUser();
     
        toast.success('Your card was remove from the account')
      }
    })

  }


  updatePrice = async () => {

    console.log(this.state.price)
    // Change this is wrong doing  


    if(this.state.priceOriginal === this.state.price) {
      return null;
    } else {

      Swal.fire({
        title: 'Are you sure?',
        text: "You would change the current price in your account",
        showCancelButton: true,
        confirmButtonColor: '#9C7DF2',
        cancelButtonColor: '#adb5bd',
        confirmButtonText: 'Change price',
        background:"#000",
        color: "#fff"
      }).then((result) => {
        if (result.isConfirmed) {
        this.priceUpdateDB()

        }
      })


        
    }

  }

  priceUpdateDB = async () => {
   
    await db.collection("users").doc(auth.currentUser.uid).update({price: Number(this.state.price)}).then(() => {
      toast.success('Precio de perfil actualizado')
    });
  }

  renderPrices() {
    return (
      <>
        <option key={0} value={0}>Free</option>
        {preciosTest.map(data => (
           <option key={data.price} value={data.price}>{data.price}€</option>
      ))}
      </>
    )
  }

  renderInputMethod() {
  if(this.state.retireMethod === "paypal") {
          return (
            <input 
              type="Paypal account email" 
              value={this.state.retireAccount}
              onChange={(e) => this.setState({retireAccount:e.target.value})}
              className="form-control form-control-lg mb-2" 
              name="amount" 
              id="amount" 
              placeholder="janedoe@mail.com" 
              required 
          />
          )
        } else {
          return (
            <input 
            type="Bank account IBAN" 
            value={this.state.retireAccount}
            onChange={(e) => this.setState({retireAccount:e.target.value})}
            className="form-control form-control-lg mb-2" 
            name="amount" 
            id="amount" 
            placeholder="AT 48 3200 0000 1234 5864" 
            required 
            />
          )
        }

  }

  retire() {
      if(this.state.retireAmount === 0 || this.state.retireAccount === null) {
        
        if(this.state.retireAccount === null ) {
          toast.error("Rellena todos los campos para completar la retirada de dinero")

        } else {
        toast.error("You cannot withdrawal more than the balance that you have in you account")
        }
      
      } else {
        this.setState({retireLoading: true})
     
      if(this.state.retireAmount < 1) {
      
        toast.error("You cannot withdrawal, you cannot withdrawal less than 1€")
      } else {
        if(this.state.retireAmount > this.state.balance) {
         
            toast.error("Withdrawal failed", "You cannot withdrawal more than the balance that you have in you account")
        } else {
          const data = {
              amount: -this.state.retireAmount,
              date: Date.now(),
              user: auth.currentUser.uid,
              retire: true,
              method: this.state.retireMethod,
              retireAccount: this.state.retireAccount,
              type: `Withdrawal of money to ${this.state.retireMethod} account`,
              }
              
          db.collection("users").doc(auth.currentUser.uid).collection("transactions").add(data)
  
          db.collection("withdraws").add(data);
  
          setTimeout(() => {
            this.getUser()
            this.setState({showModal: false, retireLoading: false})
            toast.success('Retiro de dinero efectuado correctamente')
          }, 2000);
          
        }  
      }
  }
  }

  render() {

      return (
      <main className="mt-4 mb-5">
        {/* Modal retire more  */}
        <Modal  variant="dark" show={this.state.showModal} onHide={() => this.setState({showModal: false})}    aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header  className="bg-dark" closeButton>
            <Modal.Title>Retirar fondos</Modal.Title>
          </Modal.Header>
          
          <Modal.Body className="bg-dark  text-left">
            <label className="mb-2">Cantidad a retirar</label>
            <input 
                type="email" 
                value={this.state.retireAmount}
                onChange={(e) => this.setState({retireAmount:e.target.value})}
                className="form-control form-control-lg mb-2" 
                name="amount" 
                id="amount" 
                placeholder="100€" 
                required 
            />
            <div className="d-flex">
                <a className="badgeDonation"  onClick={() => this.setState({retireAmount:this.state.balance})}>Retirar todo </a>
                <a className="badgeDonation" onClick={() => this.setState({retireAmount:this.state.balance * 0.5})}>50%</a>
            </div>
            <label>Selecciona tipo de retirada</label>
            <select className="form-control mt-2 mb-2"   value={this.state.retireMethod}  onChange={(e) =>  this.setState({ retireMethod : e.target.value})} id="exampleFormControlSelect1">
                <option value={"paypal"}>Transferencia Paypal - 1-2 días </option>
                <option value={"bank"}>Transferencia bancaria -  3-5 días</option>

            </select>
            {this.renderInputMethod()}


            <p className="h6 mt-2">Vas a retirar {this.state.retireAmount}€</p>           
            <br/>
           
           { this.state.retireLoading ?
            <div className="text-center mb-4">
            <div className="spinner-border text-primary" role="status">
                <h1 className="sr-only">Cargando...</h1>
            </div>
            </div>
            : null 
           }
            <div className="container d-flex justify-content-center align-items-center">
            <small className="underline text-center cursor" target="__blank" href="https://fullcontent.me/terms">Retirada siguiendo los términos y condiciones de Fullcontent</small>
            </div>
            

            <br/>

            </Modal.Body>
          <Modal.Footer className="bg-dark">
            <Button variant="outline-danger" onClick={() => this.setState({showModal: false})}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={() => this.retire()}>
              Retirar 
            </Button>
          </Modal.Footer>
        </Modal>
        {/* <div className="d-flex align-items-center d-lg-none tabbable">
                <ul className="nav nav-pills nav-tabs  small fw-normal card-body p-0 mx-1">
                   
                   <li className="nav-item py-1 " >
                       <a  href="#nav-setting-tab-1" className={this.state.tab !== "card" ?"nav-link d-flex mb-0 active":"nav-link d-flex mb-0 "} data-bs-toggle="tab"> <span>Account </span></a>
                     </li>
{/*                     
                
                     <li className="nav-item py-1 " >
                       <a className="nav-link d-flex mb-0" href="#nav-setting-tab-3" data-bs-toggle="tab"><span>Transactions </span></a>
                     </li>
                     <li className="nav-item py-1 " >
                       <a  href="#nav-setting-tab-4" data-bs-toggle="tab" className={this.state.tab === "card" ?"nav-link d-flex mb-0 active":"nav-link d-flex mb-0 "}> <span>Cards </span></a>
                     </li>
                     <li className="nav-item py-1 nav-pill-danger " onClick={() => logout()}>
                       <div className=" d-flex mb-0 text-white btn btn-danger btn-sm"  ><span>Logout </span></div>
                     </li>
               
                </ul>
              </div> */}
        {/* Modal retire money end */}
        {/* Container START */}
        <div className="container">
          <div className="row">

              {/* Main content START */}
              <div className="col-lg-6   vstack gap-4">
              {/* Setting Tab content START */}
              <div className="tab-content py-0 mb-0  p-0  m-0 mt-0 ">
                {/* Account setting tab START */}                 

                <div  className={this.state.tab !== "card bg-dark" ?"tab-pane show active fade":"tab-pane fade"}  id="nav-setting-tab-1">
                  {/* Account settings START */}
                  <div className="card mb-4 bg-dark">
                    {/* Title START */}
                    <div className="card-header border-0 pb-0 bg-dark">
                      <h1 className="h5 card-title">Edita tu perfil</h1>
                      <p className="mb-0">Configura la aperencia de tu perfil de FulLContent</p>
                    </div>
                    {/* Card header START */}
                    {/* Card body START */}
                    <div className="card-body bg-dark">
                    <div className="card">
                      <div className="h-200px rounded-top border-0" style={{backgroundImage: `url(${this.state.banner})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}} />
                      {/* Card body START */}
                      <div className="card-body bg-dark text-center pt-0 border-0">
                        {/* Avatar */}
                        <SelectImage ComponentRender={() => <>
                          <a className="btn btn-primary-soft btn-sm position-absolute d-flex mt-2">Cambiar</a>
                          </>} onDone={(newItem) => this.setState({banner:newItem})}/>
                        
                        <div className="avatar avatar-xl mt-n5 mb-3">
                          <a><img className="avatar-img rounded-circle border border-white border-3 bg-white" src={this.state.img} alt="" /></a>
                         
                          <SelectImage ComponentRender={() => <>
                            <a className="icon-md btn btn-primary rounded-circle iconProfile rounded circle" >
                              <i className="bi bi-camera fs-6"> </i>
                            </a>
                          </>} onDone={(newItem) => this.setState({img:newItem})}/>
                          
                        </div>
                        {/* Info */}
                        <h4 className="mb-0"> <a>{this.state.name}</a> </h4>
                        <label className=""> <a>@{this.state.username}</a> </label>

                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6 cold-md-4">
                          <img src={this.state.image}></img>
                        </div>

                        <div className="col-6 cold-md-4"></div>

                      </div>

                      {/* Form settings START */}
                      <div className="row g-3">
                        {/* First name */}
                        <div className="col-sm-6 col-lg-6">
                          <label className="form-label">Nombre</label>
                          <input   value={this.state.name} onChange={(e) => this.setState({name:e.target.value})} type="text" className="form-control"    />
                        </div>
                      
                        {/* User name */}
                        <div className="col-sm-6">
                          <label className="form-label">Usuario</label>
                          <input type="text"  value={this.state.username} onChange={(e) => this.setState({username:e.target.value})}  className="form-control"  default  />
                          {this.state.taken == true ? <span className="text-danger small">Username already taken</span> :null}
                        </div>
                     
                        {/* Birthday
                        <div className="col-lg-6">
                          <label className="form-label">Birthday </label>
                          <input type="text"  value={this.state.birthday} onChange={(e) => this.setState({birthday:e.target.value})} className="form-control flatpickr" />
                        </div>
                

                        <div className="col-lg-6">
                          <label className="form-label">Profile link</label>
                          <input type="text" placeholder="https://link.to" value={this.state.profileLink} onChange={(e) => this.setState({profileLink:e.target.value})} className="form-control flatpickr" /> 
                        </div>
                        */}
                        {/* {this.state.profileLinkError == true ? <span className="text-danger small">Enter a valid link</span> :null} */}

                        <div className="col-12">
                          <label className="form-label">Descripción cuenta</label>
                          <textarea className="form-control" value={this.state.description} onChange={(e) => this.setState({description:e.target.value})} rows={4} />
                          <small>Limite de caracteres: 300</small>
                        </div>
                        {/* Button  */}
                        <div className="col-12 text-end">
                          <button  className="btn btn-sm btn-primary mb-0" onClick={() => this.updateUser()}>Guardar</button>
                        </div>
                      </div>
                      {/* Settings END */}
                    </div>
                    {/* Card body END */}
                  </div>
                  {/* Account settings END */}
                  {/* Change your password START */}
              
                  {/* Card END */}
                </div>
                <div  className={this.state.tab === "card bg-dark" ?"tab-pane show active fade bg-dark":"tab-pane fade bg-dark"}   id="nav-setting-tab-4">
                  {/* Card START */}
                  
                  <div className="card bg-dark">
                    {/* Card header START */}
                    <div className="card-header bg-dark border-0 pb-0 ">
                      <div className="row">
                      <div className="col-md-6 col-12 col-md-6">
                        <h5 className="card-title">Payment methods</h5>
                        <p className="mb-0">Add your card so you can make payments in Fullfans</p>
                      </div>

                      </div>
                    
                   
                    </div>

                    {/* Card header START */}
                    {/* Card body START */}
                    <div className="card-body bg-dark">
                    { this.state.paymentMethod ?  
                        <div className="hstack gap-2 mb-3 bg-white cardDebit" >
                        {/* Avatar */}
                        <div className="avatar avatar d-flex justify-content-center align-items-center ml-3">
                        { this.state.paymentMethod.card ?  <img className="img-fluid pr-3"  src={images[this.state.paymentMethod.card.brand]} alt="" /> : null }
                        </div>
                        {/* Title */}
                        <div/>
                        <div className="overflow-hidden ml-3 ">
                          <a className="h6 mb-0 text-dark"> •••• •••• •••• {this.state.paymentMethod?.card?.last4} </a>
                          <p className="mb-0  text-truncate">{this.state.paymentMethod?.card?.exp_month}/{this.state.paymentMethod?.card?.exp_year} </p>

                        </div>
                        {/* Button */}
                        <a onClick={() => this.borrarMetodo()} className="btn btn-danger cursor rounded-circle icon-md ms-auto" >
                        { this.state.paymentMethod ?    <i className="bi bi-trash "/> : null}
                       
                        </a>
                      </div>

                    : 
                      <div className="mt-3">
                      <h5  className="text-center">You don't have any payment methods</h5>
                      </div>
                    }

                      <h5 className="card-title mt-5">Add a new card</h5>
                      <p className="mb-1 mt-0">Add your card so you can make payments in Fullfans</p>
                 


                    

                    </div>
                    {/* Card body END */}
                  </div>
                  {/* Card END */}
                  
                </div>

{/* 
                <div className="tab-pane fade" id="nav-setting-tab-2">

                  <div className="card">
                    <div className="card-header border-0 pb-0">
                      <h5 className="card-title">Subscription price</h5>
                      <p className="mb-0">You can monetize your content by adding a monthly subscription price for accessing your conten</p>
                    </div>

                    <div className="card-body pb-0 mb-3">
                        <li className="row d-flex  align-items-center ">
                          <div className="col-md-6 col-12">
                            <h6 className="mb-0">Profile monthly cost</h6>
                            <p className="small mb-0">You can change your price of subscription. <br/> Subscription in promote in your profile with an anual discount of 30%.</p>
                            <div className="col-12">
                                <a className="btn btn-primary mt-3" disabled={this.state.priceOriginal !== this.state.price} onClick={() => this.updatePrice()}>Change price</a>
                            </div>
                          </div>
                          <div className="ml-4 col-md-6 col-12">
                          <div className="form-group">
                            <label>Select price for your profile</label>
                            <select className="form-control"   value={this.state.price}  onChange={(e) =>  this.setState({ price : e.target.value})} id="exampleFormControlSelect1">
                                {this.renderPrices()}
                            </select>
                            </div>
                          </div>
                        </li>



                       
                    </div>
                  </div>
                </div> */}

             

                <div className="tab-pane fade bg-dark" id="nav-setting-tab-3">
                  {/* Card START */}
                  
                  <div className="card bg-dark">
                    {/* Card header START */}
                    <div className="card-header border-0 pb-0  bg-dark">
                      <div className="row">
                      <div className="col-12 col-md-6">
                        <h5 className="card-title">Transactions</h5>
                        <p className="mb-0">Here are all the transactions made in your account</p>
                        <div className="col-6 col-md-6 mt-3">
                        <a   onClick={() => this.setState({showModal: true})} className="btn btn-sm btn-primary mb-3 float-right">Retire funds</a>
                      </div>
                    
                      </div>
                      <div className="col-12 col-md-6">
                        <label>Account balance</label>
                        <h2>{this.state.balance}€</h2>
                      </div>
                      </div>
                    
                   
                    </div>
                    </div>

                    <div className="card mt-3 bg-dark">
                    {/* Card header START */}
                    <div className="card-body bg-dark mt">
                          {this.renderTransactions()}
                          <AddContent/>
                    </div>
                    
                    {/* Card body END */}
                    </div>
                  {/* Card END */}
                </div>
           
            
              </div>
              {/* Setting Tab content END */}
            </div>
            {/* Sidenav START */}
            <div className="col-lg-3 col-md-3  p-0 mb-0 pb-0">
              {/* Advanced filter responsive toggler START */}
              {/* Divider */}
             
              <nav className="navbar navbar-light navbar-expand-lg mx-0">
                <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasNavbar">

                  <div className="offcanvas-header">
                    <button type="button" className="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close" />
                  </div>

                  <div className="offcanvas-body p-0">

                    <div className="card w-100 bg-dark">

                      <div className="card-body">

                        <ul className="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                          
                      
                          <li className="nav-item" data-bs-dismiss="offcanvas">
                            <a className={this.state.tab !== "card" ?"nav-link d-flex mb-0 active":"nav-link d-flex mb-0 "} href="#nav-setting-tab-1" data-bs-toggle="tab"><span  className="text-white">Cuenta </span></a>
                          </li>
                      
                          <li className="nav-item" data-bs-dismiss="offcanvas">
                            <a className="nav-link d-flex mb-0" href="#nav-setting-tab-3" data-bs-toggle="tab"> <span className="text-white">Wallet </span></a>
                          </li>
                          {/* <li className="nav-item" data-bs-dismiss="offcanvas">
                            <a href="#nav-setting-tab-4" data-bs-toggle="tab" className={this.state.tab === "card" ?"nav-link d-flex mb-0 active":"nav-link d-flex mb-0 "}><span>Cards </span></a>
                          </li>
                         */}
                          
                        </ul>

                      </div>


                      <div className="card-footer text-center py-2 bg-dark">
                        <a className="btn btn-link text-danger btn-sm" onClick={() => logout()}>Cerrar sesión</a>
                      </div>
                    </div>

                  </div>


                </div>
              </nav> 
            </div>
            {/* Sidenav END */}
          
          </div> {/* Row END */}
        </div>
        {/* Container END */}
      </main>

      
      );

    }
  }


export default Settings;
