import React from "react";

import {  auth, db } from "../firebase";

import {  Button } from 'react-bootstrap';

import NotFound from "../assets/notfound.png"

import { Link } from "react-router-dom/cjs/react-router-dom";
import AddContent from "../Components/AddContent";



class Profile extends React.Component {



  constructor(props) {
    
    super(props) 
    this.state = {
        tab:1,
        loading:true,
        sesionsAmmount: 0,  
        selectedDay: null,
        selectedHour: null,
        subscribe: false,
        check:true, 
        therapistLog:false,
        sesions: [],  
        hours:[
          {hour:"9:00", reserved: false},
          {hour:"10:00", reserved: false},
          {hour:"11:00", reserved: false},
          {hour:"12:00", reserved: false},
          {hour:"13:00", reserved: false},
          {hour:"14:00", reserved: false},
          {hour:"15:00", reserved: false},
          {hour:"16:00", reserved: false},
          {hour:"17:00", reserved: false},
          {hour:"18:00", reserved: false},
          {hour:"19:00", reserved: false},
          {hour:"20:00", reserved: false},
        ],
       
    }
  }

  resetHours() {
    this.setState({
      hours: [
          {hour:"9:00", reserved: false},
          {hour:"10:00", reserved: false},
          {hour:"11:00", reserved: false},
          {hour:"12:00", reserved: false},
          {hour:"13:00", reserved: false},
          {hour:"14:00", reserved: false},
          {hour:"15:00", reserved: false},
          {hour:"16:00", reserved: false},
          {hour:"17:00", reserved: false},
          {hour:"18:00", reserved: false},
          {hour:"19:00", reserved: false},
          {hour:"20:00", reserved: false},]
    })
  }

  componentDidMount() {
    this.getUser()
    setTimeout(() => {
        this.getUser()
    }, 2000);

  }


renderPost(item)  {

  return(
    <div className="card   shadow m-2 " onClick={() => this.openSesion(item)}>
      <div className="d-flex  align-items-center ">
      <div className="avatar avatar-xl mt-2 mb-3 ">
        <a>
        <img className="avatar-img rounded-circle border border-white border-3 bg-dark" src={item.therapistData?.img ||  "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"} alt="" />
        </a>

          </div>
            <div className="">
            <h5 className=" mb-0 mt-1">Sesión <b>{this.state.therapistData?.name}</b> reservada </h5>
            <h5>{item.dayDisplay}</h5>
            </div>
            <div className="">
             <h4 className="badge bg-dark m-3">{item.hour}</h4>
              
            </div>
            <Button variant="outline-primary" text="" className="mx-4">
            Ver detalles sesión
            </Button>


            
            </div>         
        </div>
  )

}

renderPosts =  () => {

    var data = this.state.sesions;

    if(data.length > 0) {
      return(data.map(item => this.renderPost(item)) )
    } else {
      return(
        <>
        <div className="text-center justify-content-center">
          <h5 className="text-center">No tienes publicaciones</h5>
        </div>
        </>
      )
    }
   
  }

  getUser = async () => { 
      if(auth.currentUser) {
        await db.collection('users').doc(auth.currentUser.uid).get()
        .then(doc => {
            const data = doc.data();
            this.setState(data)
        
        })
          this.getSesions()
          this.setState({loaded: true})

        } else {
            window.location.href ="/register"
        }
  }

  addS(newItem) {
      this.setState({ sesions: [...this.state.sesions, newItem] })
    }


  async getSesions() {
        this.setState({sesions:[]});
        await db.collection("users").doc(auth.currentUser?.email).collection('sesions').get()
        .then(res => {
            res.forEach(doc => {

                let newItem = doc.data();
                this.addS(newItem)

            });
        })
        .catch(err => { console.error(err) });
    } 


  renderProfile() {
    return (
      <main>
      <div>
        {/* Page content*/}
      <div className="container ">
          {/* Page content*/}
          <div className="row">
            {/* Sidebar*/}
            <aside className="col-lg-4 col-md-5 pe-xl-4 mb-5">
              {/* Account nav*/}
              <div className="   shadow rounded  pb-1  mt-3 p-3 mt-5">
              <div className="">
                      <div className="h-00px rounded" style={{backgroundImage: `url(${this.state.banner})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}} />
                      {/* Card body START */}
                      <div className=" text-center pt-0">
                        {/* Avatar */}
               
                        <div className="avatar avatar-xxl mt-n5 mb-3">
                          <a><img className="avatar-img rounded-circle border border-white border-3 bg-white" src={this.state.img} alt="" /></a>
                         

                        </div>
                        {/* Info */}
                        <h4 className="mb-0"> <a>{this.state.name}</a> </h4>
                        <label className=""> <a>@{this.state.username}</a> </label>

                        </div>
                        <Link to="/add" className="btn btn-outline-primary   w-100 mb-3 bold text-white mt-3"  onClick={() => this.setState({modalOpen: true})}><i className="fi-plus me-2" />Añadir contenido</Link>
              
                        <div className="d-flex align-items-center justify-content-center">
                        <Link type="button" className="" to="/edit">
                            Edita tu cuenta
                      </Link>
                      </div>
                      </div>
             
            <br/>
              </div>
            </aside>
            {/* Content*/}
            <div className="col-lg-8 col-md-7 mb-5 mt-2 pt-2">

            <p className="pt-2 mt-2">Tus posts</p>
            

            {this.renderPosts()}

            </div>
            <AddContent/>
            </div>

        </div>
      </div>

      </main>
    )
  }

  render() {
      return this.renderProfile()
  }

 }


  export default Profile;