import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { auth, sendPasswordResetEmail } from "../firebase";


import Footer from "../Components/Footer";


function Reset() {
  const [email, setEmail] = useState("");
  const [user, loading] = useAuthState(auth);
  const history = useHistory();

  useEffect(() => {

    if (loading) return;
    if (user) history.replace("/profile");
  }, [user, loading]);

  return (
    <main>
    <div className="reset container content-space-1 content-space-t-md-1  mt-4 ">
       <br />
        <br/>
        <div className="card card-lg zi-2 mx-auto width-40 p-3 bg-dark  ">
       
      <div className="mt-3 pb-2 ">

      <h4 className="card-title text-white">Reseta tu contraseña de Fullcontent </h4>
      <p>Mandaremos un email a tu correo para que cambies la contraseña de tu cuenta de Fullcontent </p>
      <br />
        <input
          type="text"
          className="js-toggle-password form-control form-control-lg"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Dirección de E-mail"
        />
        <br/>
        <button
          className="btn btn-primary"
          onClick={() => sendPasswordResetEmail(email.toLowerCase())}
        >
          Enviar email de reseteo
        </button>
        <br /> <br />
        <div>
         ¿No tienes una cuenta? <Link to="/register" className="text-primary">Registrate </Link> ahora.
        </div>
      </div>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>


    </div>
         <Footer></Footer>
    </main>
  );
}

export default Reset;
